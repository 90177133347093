import Highway from '@dogstudio/highway';

export default class PageSimple extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('PageSimple');
    this.DOM = { view: this.wrap.lastElementChild };

    // Header Dark
    this.DOM.header = document.querySelector('.Header');
    this.DOM.header.classList.add('is--dark');
  }

  onLeaveCompleted() {
    this.DOM.header.classList.remove('is--dark');
  }
}
