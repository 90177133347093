/* global DEVMODE, TimelineMax, Power3 */

import FastClick from 'modern-fastclick';

import { newSniff } from './modules/sniffer.js';
import Header from './parts/header.js';
import Footer from './parts/footer.js';
import Partners from './parts/partners.js';
import Routes from './modules/routes.js';

import {randomInt} from './utils.js';

window.DEVMODE = DEVMODE === 'development';
window.Sniff;

class App {

  constructor() {

    // Declarations
    this.sy = 0;
    this.sniffer;
    this.DOM = {};
    this.DOM.Body = document.body;
    this.DOM.App = this.DOM.Body.querySelector('.App');
    this.DOM.Header = this.DOM.Body.querySelector('.Header');
    this.DOM.Footer = this.DOM.Body.querySelector('.Footer');
    this.DOM.Partners = this.DOM.Body.querySelector('.App + .Partners');
    this.DOM.Loader = this.DOM.Body.querySelector('.Loader');
    this.DOM.Outdated = this.DOM.Body.querySelector('#Outdated');

    // Signature Wokine
    console.log('%cCreated by Wokine, with ❤', 'color: #000; padding: 5px 0px 1px; border-bottom:2px solid #71d1c2;');
    console.log('%c⚡ http://wokine.com ⚡', 'color:#ccc');

    this.init();
    // this.addEvents();

  }

  init() {

    // DevMode
    if (window.DEVMODE) console.log('🔥 DEVMODE  🔥');


    // Sniffer
    window.Sniff = newSniff();


    // OutdatedBrowser
    if (window.Sniff.browser.name === 'ie' && window.Sniff.browser.majorVersion <= 10) {
      console.log('<= ie010');
      this.DOM.Body.classList.remove('is-first');
      this.DOM.Body.classList.remove('is-loading');
      this.DOM.Outdated.classList.add('show');
      return;
    }


    // Chrome Scroll Manual
    this.DOM.Body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    history.scrollRestoration = 'manual';

    // Create Header/Footer/Routes/Partners
    this.Header = new Header(this.DOM.Header);
    this.Footer = new Footer(this.DOM.Footer);
    this.Routes = new Routes(this.Header);
    this.Partners = new Partners(this.DOM.Partners);

    // DOMContentLoaded
    document.addEventListener('DOMContentLoaded', () => {

      const queryString = window.location.search;

      const lang_language_switcher = document.getElementsByClassName("Lang__menu");
      const lis = lang_language_switcher[0].querySelectorAll('a');

      console.log('lis.forEach');
      lis.forEach(function (li, i) {
        console.log(queryString);
        lis[i].href = lis[i].href + queryString;
      });

      // Add Fast Click
      new FastClick(document.body);
      // Reset Scroll
      window.scrollTo(0, 0);
      // Intro
      this.intro();

    }, false);

  }

  addEvents() {
    window.addEventListener('scroll', () => {
      this.sy = window.pageYOffset || document.documentElement.scrollTop;
    });

  }

  intro() {

    const intro = new TimelineMax({
      paused: true,
      onStart: () => {
        this.DOM.Body.classList.remove('is-first');
        this.DOM.Body.classList.remove('is-loading');
      },
      onComplete: () => {
        this.DOM.Body.classList.remove('is-animating');
      },
      delay: 0.6
    });

    intro.staggerFromTo(this.DOM.Loader.querySelectorAll('.Brand .mottez > *'), 1, { y: -1 * randomInt(10, 40), opacity: 0 }, { y: 0, opacity: 1, ease: Power3.easeOut, clearProps: 'all' }, 0.02, 0);
    intro.fromTo(this.DOM.Loader.querySelector('.Brand .line'), 1, { scaleX: 0 }, { scaleX: 1, ease: Power3.easeOut, clearProps: 'all' }, 0);
    intro.fromTo(this.DOM.Loader.querySelector('.inner'), 1, { y: 0 }, { y: '75%', ease: Power3.easeInOut, clearProps: 'all' }, 0.8);
    intro.fromTo(this.DOM.Loader, 1, { y: 0 }, { y: '-100%', ease: Power3.easeInOut, clearProps: 'all' }, 0.8);
    intro.play();

  }


}

new App();
