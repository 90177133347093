import Highway from '@dogstudio/highway';

export default class PageContact extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('PageContact');
    this.DOM = { view: this.wrap.lastElementChild };

    // Partners Background
    this.DOM.partners = document.querySelector('.Partners');
    this.DOM.partners.classList.add('has--border');

    $("#form_contact").on("submit",function(e){
      e.preventDefault();
      $('body').css( 'cursor', 'wait');
      $("button").prop("disabled",true);

      var self = $("#form_contact");
      var ajaxurl = self.data("ajaxurl");

      $.ajax({
        type: "POST",
        url: ajaxurl,
        data: self.serialize(),
        success: function(data) {
          $("button").prop("disabled",false);
          $('body').css( 'cursor', 'default');
          data = JSON.parse(data);
          document.getElementById("output").innerHTML = data.message;
          if (data.status != false) {
            console.log("success");
            self.trigger('reset');
            document.getElementById("output").style.color = "black";
          }
         },
         error: function(data) {
           console.log(data);
           $("button").prop("disabled",false);
           $('body').css( 'cursor', 'default');
           $('.output').text("error");
         }
       });

    })

  }

  onLeaveCompleted() {
    this.DOM.partners.classList.remove('has--border');
  }
}
