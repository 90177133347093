export default class Header {

  constructor(el) {

    this.DOM = { el: el };
    this.DOM.body = document.body;
    this.DOM.brand = this.DOM.el.querySelector('.brand');
    this.DOM.toggle = this.DOM.el.querySelector('.Toggle__menu');
    this.DOM.menuContainer = this.DOM.el.querySelector('Nav__container');
    this.menuOpen = false;

    this.init();
    this.addEvents();

  }

  init() {

    if (window.DEVMODE) console.log('initHeader');

    // Toggle
    this.DOM.toggle.addEventListener('click', (e) => {

      e.preventDefault();
      this.menuOpen === true ? this.close() : this.open();

    });

  }

  addEvents() {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 992 && this.menuOpen) {
        this.close();
      }
    }, { capture: false, passive: true});
  }

  open() {

    return new Promise((resolve, reject) => {

      if (window.DEVMODE) console.log('Open Menu');
      this.menuOpen = true;
      this.DOM.body.classList.add('showMenu');
      this.DOM.body.style.overflow = 'hidden';

    });

  }

  close() {

    return new Promise((resolve, reject) => {

      if (window.DEVMODE) console.log('Close Menu');
      this.menuOpen = false;
      this.DOM.body.classList.remove('showMenu');
      this.DOM.body.style.overflow = '';

    });

  }

}
