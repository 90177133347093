// Highway
import Highway from '@dogstudio/highway';
import PageHome from '../pages/page-home.js';
import PageSimple from '../pages/page.js';
import PageGamme from '../pages/page-gamme.js';
import SingleProduct from '../pages/single-product.js';
import ArchiveNews from '../pages/page-news.js';
import SingleNews from '../pages/single-news.js';
import PageContact from '../pages/page-contact.js';
import PageAbout from '../pages/page-about.js';
import DefaultPageTransition from '../pages/page-transition.js';
import PageSearch from '../pages/page-search.js';
import PageCategory from '../pages/page-category.js';

import pagePrllx from './prllx.js';
import pageInView from './inview.js';
import pageAnchors from './anchors.js';

export default class Routes {

  constructor(Header) {

    this.view = {};
    this.header = Header;
    this.navLinks = this.header.DOM.el.querySelectorAll('.Nav__link');

    this.createHighway();
    this.initView(document.body);

  }


  createHighway() {

    const H = new Highway.Core({
      renderers: {
        pageHome: PageHome,
        pageSimple: PageSimple,
        pageGamme: PageGamme,
        singleProduct: SingleProduct,
        archiveNews: ArchiveNews,
        singleNews: SingleNews,
        pageContact: PageContact,
        pageAbout: PageAbout,
        pageSearch: PageSearch,
        pageCategory: PageCategory,
      },
      transitions: {
        default: DefaultPageTransition
      }
    });

    H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {

      if (window.DEVMODE) {
        console.log('NAVIGATE_OUT');
      }

      // RemovePrllx
      this.view.prllx.destroy();

    });

    H.on('NAVIGATE_IN', ({ to, trigger, location }) => {
      const lang_language_switcher = document.getElementsByClassName("Lang__menu");
      const lis = lang_language_switcher[0].querySelectorAll('a');
      const lang_language_switcher2 = to.page.getElementsByClassName("Lang__menu");
      const li2s = lang_language_switcher2[0].querySelectorAll('a');

      li2s.forEach(function (li2, i) {
        lis[i].href = li2s[i].href;
      });

      if (window.DEVMODE) {
        console.log('NAVIGATE_IN');
      }

      // Menu active links
      this.navLinks.forEach((link) => {
        link.classList.remove('is-active');
        if (link.href === location.href) {
          link.classList.add('is-active');
        }
      });

      this.initView(to.view);

    });

    H.on('NAVIGATE_END', ({ to, from, trigger, location }) => {

      if (window.DEVMODE) {
        console.log('NAVIGATE_END');
      }

      if (tarteaucitron.launch.gtag) {
        gtag('config', tarteaucitron.user.gtagUa, {
          'page_path': location.pathname,
          'page_title': to.page.title,
          'page_location': location.href
        });
      }

    });

  }

  initView(container) {

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    // Menu Toggle
    if (this.header.menuOpen) {
      const click = new Event('click');
      this.header.DOM.toggle.dispatchEvent(click);
    }

    // Check Anchors
    if (container.querySelector('.anchors__nav')) {
      this.view.anchors = new pageAnchors(container.querySelector('.anchors__nav'));
    }

    // Prllx / InView
    this.view.prllx = new pagePrllx(container);
    this.view.inview = new pageInView(container);

  }

}
