import { getPosition } from "../utils.js";
import Highway from '@dogstudio/highway';

export default class PageCategory extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('PageCategory');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.pageContent = this.DOM.view.querySelector('.Page__content');
    this.DOM.aside = this.DOM.pageContent.querySelector('aside');
    this.onScrollThis = this.onScroll.bind(this);

    this.init();
    this.addEvents();

  }

  init() {
    var body_tag = document.getElementsByTagName("body")[0];
    $('.category').on("click", function(e) {
      e.preventDefault();
      body_tag.style.cursor = 'wait';

      var self = $(this);
      var name = self.data("name");
      var slug = self.data("slug");
      var ajaxurl = $('#categories_nav').data("ajaxurl");

      $('.category').removeClass("is--active");
      self.addClass("is--active");

      $('#current_tax_name').data("ajaxurl");
      document.getElementById("current_tax_name").textContent = name;

      $.ajax({
        type: "POST",
        url: ajaxurl,
        data: { 'slug': slug },
        success: function(data) {
          body_tag.style.cursor = 'default';
          var data = $.parseJSON(data);
          console.log(data);
          if (data.status == true) {
            $("#products_list").html(data.html);
          } else {
            console.log("error");
          }
        },
        error: function(data) {
          body_tag.style.cursor = 'default';
          var data = $.parseJSON(data);
          console.log(data);
        }
      });
    })
  }

  onScroll() {
    this.pageScroll = window.pageYOffset || document.documentElement.scrollTop;
    TweenMax.ticker.addEventListener('tick', this.pageRender, this, true, 1);
  }

  addEvents() {
    window.addEventListener('scroll', this.onScrollThis);
  }

  pageRender() {
    if (this.pageScroll >= getPosition(this.DOM.pageContent).y && this.pageScroll <= getPosition(this.DOM.pageContent).y + this.DOM.pageContent.offsetHeight - window.innerHeight && window.innerWidth > 992) {
      TweenMax.set(this.DOM.aside, {y:this.pageScroll - getPosition(this.DOM.pageContent).y});
    }
  }

  onleave() {
    window.removeEventListener('scroll', this.onScrollThis);
    TweenMax.ticker.removeEventListener('tick', this.pageRender);
  }

}
