import Highway from '@dogstudio/highway';

export default class ArchiveNews extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('archiveNews');
    this.DOM = { view: this.wrap.lastElementChild };

    // Partners Background
    this.DOM.partners = document.querySelector('.Partners');
    this.DOM.partners.classList.add('has--border');

    var cat = "";

    $('.filter').on('click',function(){
        cat = $(this).data("slug");
        $('input').prop( "checked", false );
        $(this).children('input').prop( "checked", true );
        if (cat == "all") {
          $('.News__preview').fadeIn();
        }else {
          $('.News__preview').hide();
          $('.'+cat).fadeIn();
        }
    })

    // PRESELECTION TYPE D EVENT
    function $_GET(param) {
    	var vars = {};
    	window.location.href.replace( location.hash, '' ).replace(
    		/[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
    		function( m, key, value ) { // callback
    			vars[key] = value !== undefined ? value : '';
    		}
    	);
    	if ( param ) {
    		return vars[param] ? vars[param] : null;
    	}
    	return vars;
    }

    var preselect = $_GET('cat');

    if (preselect) {
      setTimeout(function(){
        $("#filter_"+preselect).trigger( "click" );
        // var step_popup = {page:"news"};
        // window.history.pushState(step_popup, 'news', 'text');
      }, 600);
    }

  }

  onLeaveCompleted() {
    this.DOM.partners.classList.remove('has--border');
  }

}
