import Highway from '@dogstudio/highway';

export default class SingleProduct extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('SingleProduct');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.gallerySwiper = this.DOM.view.querySelector('.Product__gallery');
    this.DOM.productSwiper = this.DOM.view.querySelector('.Products__swiper');

    if (this.DOM.gallerySwiper) this.initGallery();
    if (this.DOM.productSwiper) this.initRelated();

    this.initModals();

    // Header Dark
    this.DOM.header = document.querySelector('.Header');
    this.DOM.header.classList.add('is--dark');


    $("#form_devis").on("submit",function(e){
      e.preventDefault();
      $('#ModalDocs').css( 'cursor', 'wait');
      $("button").prop("disabled",true);

      var self = $(this);
      var ajaxurl = self.data("ajaxurl");

      $.ajax({
        type: "POST",
        url: ajaxurl,
        data: self.serialize(),
        success: function(data) {
          data = JSON.parse(data);
          $("button").prop("disabled",false);
          $('#ModalDocs').css( 'cursor', 'default');
          document.getElementById("output_devis").innerHTML = data.message;
          if (data.status != false) {
            document.getElementById("output_devis").style.color = "black";
            self.trigger('reset');
          }
         },
         error: function(data) {
           console.log(data);
           $("button").prop("disabled",false);
           $('#ModalDocs').css( 'cursor', 'default');
           document.getElementById("output_devis").innerHTML = "error";
         }
       });
    })


    $("#form_dataSheet").on("submit",function(e){
      e.preventDefault();
      $('#ModalDocs').css( 'cursor', 'wait');
      $("button").prop("disabled",true);

      var self = $(this);
      var ajaxurl = self.data("ajaxurl");

      $.ajax({
        type: "POST",
        url: ajaxurl,
        data: self.serialize(),
        success: function(data) {
          data = JSON.parse(data);
          $("button").prop("disabled",false);
          $('#ModalDocs').css( 'cursor', 'default');
          document.getElementById("output_dataSheet").innerHTML = data.message;
          if (data.status != false) {
            document.getElementById("output_dataSheet").style.color = "black";
            self.trigger('reset');
            document.getElementById("dataSheet_list").style.display = "block";
            document.getElementById("content_popup").style.display = "none";
          }
         },
         error: function(data) {
           console.log(data);
           $("button").prop("disabled",false);
           $('#ModalDocs').css( 'cursor', 'default');
           document.getElementById("output_dataSheet").innerHTML = "error";
         }
       });
    })



  }


  initGallery() {

    this.gallerySwiper = new Swiper(this.DOM.gallerySwiper, {
      on: {
        slideChange: () => {
          this.updateThumbIndex(this.gallerySwiper.realIndex);
        }
      }
    });

    this.DOM.galleryThumbsContainer = this.DOM.view.querySelector('.Product__gallery__container .thumbs');
    this.DOM.galleryThumbs = this.DOM.galleryThumbsContainer.querySelectorAll('.thumb');
    this.DOM.galleryThumbs.forEach((thumb) => {
      thumb.addEventListener('click', (e) => {
        e.preventDefault();
        const slideIndex = Array.from(this.DOM.galleryThumbs).indexOf(e.currentTarget);
        this.updateThumbIndex(slideIndex);
        this.gallerySwiper.slideTo(slideIndex);
      });
    });

  }

  updateThumbIndex(index) {

    // Remove Current
    if (this.DOM.galleryThumbsContainer.querySelector('.is--active')) this.DOM.galleryThumbsContainer.querySelector('.is--active').classList.remove('is--active');
    this.DOM.galleryThumbs[index].classList.add('is--active');

  }

  initRelated() {

    this.relatedSwiper = new Swiper(this.DOM.productSwiper, {
      slidesPerView: 4,
      spaceBetween: 40,
      breakpoints: {
        520: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 25
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 40
        }
      }
    });

  }

  initModals() {

    $('[data-modal]').magnificPopup({
      preloader: false,
      focus: '#devis_surname',
      removalDelay: 400
    });

  }

  onLeaveCompleted() {
    if (this.gallerySwiper) this.gallerySwiper.destroy();
    if (this.relatedSwiper) this.relatedSwiper.destroy();
    this.DOM.header.classList.remove('is--dark');
  }
}
