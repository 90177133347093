import Highway from '@dogstudio/highway';

export default class PageGamme extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('pageGamme');
    this.DOM = { view: this.wrap.lastElementChild };

    this.DOM.categoriesSwiper = this.DOM.view.querySelector('.Categories__swiper');
    this.DOM.productSwiper = this.DOM.view.querySelector('.Products__swiper');

    if (this.DOM.categoriesSwiper) this.initCategories();
    if (this.DOM.productSwiper) this.initNouveautes();

  }

  initCategories() {

    this.categoriesSwiper = new Swiper(this.DOM.categoriesSwiper, {
      navigation : {
        nextEl: this.DOM.categoriesSwiper.querySelector('.swiper-buttons .next'),
        prevEl: this.DOM.categoriesSwiper.querySelector('.swiper-buttons .prev'),
      },
      slidesPerView: 'auto',
      spaceBetween: 40
    });

  }

  initNouveautes() {

    this.nouveautesSwiper = new Swiper(this.DOM.productSwiper, {
      slidesPerView: 4,
      spaceBetween: 40,
      breakpoints: {
        520: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 25
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 40
        }
      }
    });

  }

  onLeaveCompleted() {
    if (this.categoriesSwiper) this.categoriesSwiper.destroy();
    if (this.nouveautesSwiper) this.nouveautesSwiper.destroy();
  }
}
