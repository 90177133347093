import Highway from '@dogstudio/highway';

export default class PageSearch extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('PageSearch');
    this.DOM = { view: this.wrap.lastElementChild };

    // Partners Background
    this.DOM.partners = document.querySelector('.Partners');
    this.DOM.partners.classList.add('has--border');

    function $_GET(param) {
    	var vars = {};
    	window.location.href.replace( location.hash, '' ).replace(
    		/[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
    		function( m, key, value ) { // callback
    			vars[key] = value !== undefined ? value : '';
    		}
    	);
    	if ( param ) {
    		return vars[param] ? vars[param] : null;
    	}
    	return vars;
    }

    var keyword = $_GET('keyword');
    if (keyword) {
      keyword = decodeURI(keyword);
      setTimeout(function() {
        $("#search_bar").val(keyword);

        console.log(keyword);
        // search(keyword);
      }, 600);
    }
    //
    // function search(keyword){
    //   e.preventDefault();
    //   $('body').css( 'cursor', 'wait');
    //
    //   console.log(keyword);
    //
    //   var ajaxurl = $("#Products__list").data("ajaxurl");
    //
    //   $.ajax({
    //     type: "POST",
    //     url: ajaxurl,
    //     data: { keyword: keyword },
    //     success: function(data) {
    //       $('body').css( 'cursor', 'default');
    //       $("#Products__list").html(data.html);
    //     }
    //   });
    // })

  }

  onLeaveCompleted() {
    this.DOM.partners.classList.remove('has--border');
  }
}
