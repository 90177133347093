import Highway from '@dogstudio/highway';

export default class PageAbout extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('PageAbout');
    this.DOM = { view: this.wrap.lastElementChild };

    this.DOM.quotesSwiper = this.DOM.view.querySelector('.Quotes__swiper');
    if (this.DOM.quotesSwiper) this.initQuotes();

    // Header Dark
    this.DOM.header = document.querySelector('.Header');
    this.DOM.header.classList.add('is--dark');

    // Partners Background
    this.DOM.partnersAbout = this.DOM.view.querySelector('.Partners');
    if (this.DOM.partnersAbout) {
      this.DOM.partnersFooter = document.querySelector('.App + .Partners');
      this.DOM.partnersAbout.classList.add('has--border');
      this.DOM.partnersFooter.classList.add('is--hidden');
    }
  }

  initQuotes() {

    this.quotesSwiper = new Swiper(this.DOM.quotesSwiper, {
      virtualTranslate: true,
      navigation : {
        nextEl: this.DOM.quotesSwiper.querySelector('.swiper-buttons .next'),
        prevEl: this.DOM.quotesSwiper.querySelector('.swiper-buttons .prev'),
      },
      pagination: {
        el: this.DOM.quotesSwiper.querySelector('.swiper-pagination'),
        type: 'bullets'
      }
    });

  }

  onLeaveCompleted() {
    this.DOM.header.classList.remove('is--dark');

    if (this.DOM.partnersAbout) {
      this.DOM.partnersAbout.classList.remove('has--border');
      this.DOM.partnersFooter.classList.remove('is--hidden');
    }
    if (this.quotesSwiper) this.quotesSwiper.destroy();
  }
}
