import Highway from '@dogstudio/highway';

export default class PageHome extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('PageHome');
    this.DOM = { view: this.wrap.lastElementChild };

    this.DOM.gammesSwiper = this.DOM.view.querySelector('.Gammes__swiper');
    this.DOM.newsSwiper = this.DOM.view.querySelector('.News__swiper');
    this.DOM.quotesSwiper = this.DOM.view.querySelector('.Quotes__swiper');

    if (this.DOM.gammesSwiper) this.initGammes();
    if (this.DOM.newsSwiper) this.initNews();
    if (this.DOM.quotesSwiper) this.initQuotes();

    this.initModals();

    // Partners Background
    this.DOM.partners = document.querySelector('.Partners');
    this.DOM.partners.classList.add('is--white');
    this.DOM.partners.classList.add('has--border');

  }

  initModals() {

    $('[data-modal]').magnificPopup({
      type: 'iframe',
      preloader: false,
      removalDelay: 400
    });

  }

  initGammes() {

    this.gammesSwiper = new Swiper(this.DOM.gammesSwiper, {
      slidesPerView: 4,
      spaceBetween: 40,
      breakpoints: {
        520: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 25
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 40
        }
      }
    });

  }

  initNews() {

    this.newsSwiper = new Swiper(this.DOM.newsSwiper, {
      slidesPerView: 4,
      spaceBetween: 40,
      breakpoints: {
        520: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 25
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 40
        }
      }
    });

  }

  initQuotes() {

    this.quotesSwiper = new Swiper(this.DOM.quotesSwiper, {
      virtualTranslate: true,
      navigation : {
        nextEl: this.DOM.quotesSwiper.querySelector('.swiper-buttons .next'),
        prevEl: this.DOM.quotesSwiper.querySelector('.swiper-buttons .prev'),
      },
      pagination: {
        el: this.DOM.quotesSwiper.querySelector('.swiper-pagination'),
        type: 'bullets'
      }
    });

  }

  onLeaveCompleted() {
    if (this.gammesSwiper) this.gammesSwiper.destroy();
    if (this.newsSwiper) this.newsSwiper.destroy();
    if (this.quotesSwiper) this.quotesSwiper.destroy();

    this.DOM.partners.classList.remove('is--white');
    this.DOM.partners.classList.remove('has--border');
  }
}
