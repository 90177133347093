export default class Partners {

  constructor(el) {

    this.DOM = { el: el };
    this.DOM.App = document.body.querySelector('.App');

    this.init();

  }

  init() {

    if (window.DEVMODE) console.log('init Partners');
    const partnersSwiper = new Swiper(this.DOM.el.querySelector('.swiper-container'), {
        spaceBetween: 60,
        slidesPerView: 6,
        watchSlidesVisibility: true,
        navigation : {
          nextEl: this.DOM.el.querySelector('.swiper-buttons .next'),
          prevEl: this.DOM.el.querySelector('.swiper-buttons .prev'),
        },
        breakpoints: {
          // when window width is <= 320px
          520: {
            slidesPerView: "auto",
            spaceBetween: 20
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 40
          }
        }
    });

  }

}
