export default class Footer {

  constructor(el) {

    this.DOM = { el: el };
    this.DOM.App = document.body.querySelector('.App');

    this.init();

  }

  init() {

    if (window.DEVMODE) console.log('init Footer');

  }

}
