import Highway from '@dogstudio/highway';

export default class SingleNews extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('SingleNews');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.NewsPlayers = this.DOM.view.querySelectorAll('.player');
    this.plrys = [];

    if (this.DOM.NewsPlayers) this.initPlayers();


    // Header Dark
    this.DOM.header = document.querySelector('.Header');
    this.DOM.header.classList.add('is--dark');
    // Partners Background
    this.DOM.partners = document.querySelector('.Partners');
    this.DOM.partners.classList.add('has--border');
  }

  initPlayers() {

    this.DOM.NewsPlayers.forEach((player) => {
      const newsPlayer = new Plyr(player);
      this.plrys.push(newsPlayer);
    });

  }

  onLeaveCompleted() {

    if (this.DOM.NewsPlayers) {
      this.plrys.forEach((player) => {
        player.destroy();
      });
    }

    this.DOM.header.classList.remove('is--dark');
    this.DOM.partners.classList.remove('has--border');
  }
}
